'use client'

import type { AnyRoute } from '@mntn-dev/app-routing'
import { createContext, useContext } from 'react'
import type { PushRouteOptions } from './types.ts'

export type RouterContextType = {
  back: (count?: number) => void
  backOrPush: (route: AnyRoute) => void
  hasHistory: () => boolean
  push: (route: AnyRoute, overrides?: Partial<PushRouteOptions>) => void
  pushUrl: (url: string, overrides?: Partial<PushRouteOptions>) => void
  refresh: () => void
  removeQueryParams: (keys: string[]) => void
  replace: (url: string) => void
}

export const RouterContext = createContext<RouterContextType>({
  back: () => null,
  backOrPush: () => null,
  hasHistory: () => false,
  push: () => null,
  pushUrl: () => null,
  refresh: () => null,
  removeQueryParams: () => null,
  replace: () => null,
})

export const useRouter = () => useContext(RouterContext)
