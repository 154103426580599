'use client'

import {
  type PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import type { AnyRoute } from '@mntn-dev/app-routing'
import { LoadingModal } from '@mntn-dev/ui-components'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import { RouterContext } from './router-context.ts'
import type { PushRouteOptions } from './types.ts'

export const RouterProvider = ({ children }: PropsWithChildren) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const historyStack = useRef<string[]>([])

  // biome-ignore lint/correctness/useExhaustiveDependencies: pathname and searchParams are triggers
  useEffect(() => {
    setLoading(false)
  }, [pathname, searchParams])

  // Exposing a legacy entrypoint for router.push for things that use string URLS, like a bunch of things in onboarding.
  const pushUrl = useCallback(
    (url: string, overrides?: Partial<PushRouteOptions>) => {
      const options = { showLoading: true, ...(overrides ?? {}) }

      if (
        options.showLoading &&
        // To avoid stuck loading indicator, only show the loader if destinations are different than current url.
        `${window.location.pathname}${window.location.search}` !== url
      ) {
        setLoading(true)
      }

      !overrides?.back && historyStack.current.push(url)

      router.push(url)
    },
    [router]
  )

  // The main entrypoint for router.push given a route object.
  const push = useCallback(
    (route: AnyRoute, overrides?: Partial<PushRouteOptions>) => {
      pushUrl(route.toRelativeUrl(), overrides)
    },
    [pushUrl]
  )

  const back = useCallback(() => {
    historyStack.current.pop()
    router.back()
  }, [router])

  const replace = useCallback(
    (url: string) => {
      router.replace(url)
    },
    [router]
  )

  const refresh = useCallback(() => {
    router.refresh()
  }, [router])

  const hasHistory = useCallback(() => historyStack.current.length > 0, [])

  const backOrPush = (route: AnyRoute) => {
    if (hasHistory()) {
      back()
    } else {
      push(route, { back: true })
    }
  }

  const removeQueryParams = (keys: string[]) => {
    const updatedSearchParams = new URLSearchParams(
      Array.from(searchParams.entries()).filter(([key]) => !keys.includes(key))
    )

    router.replace(`?${updatedSearchParams.toString()}`)
  }

  return (
    <RouterContext.Provider
      value={{
        back,
        backOrPush,
        hasHistory,
        push,
        pushUrl,
        refresh,
        removeQueryParams,
        replace,
      }}
    >
      {children}
      {loading && <LoadingModal open={true} />}
    </RouterContext.Provider>
  )
}
