import {
  offerRoute,
  projectActivityRoute,
  projectFileRoute,
  projectRoute,
  projectServiceRoute,
} from '../facades.ts'
import type { LinkTarget, LinkTargetResolverMap } from './types.ts'

export const linkTargetResolver: {
  [K in keyof LinkTargetResolverMap]: (
    activityTarget: LinkTargetResolverMap[K]['props']
  ) => LinkTarget<LinkTargetResolverMap[K]['routeKey']>
} = {
  activity: ({ target, activityId }) => ({
    name: target.feed.name,
    idTag: 'activity',
    route: projectActivityRoute({ projectId: target.feed.urn, activityId }),
  }),
  feed: ({ target }) => ({
    name: target.feed.name,
    idTag: 'project',
    route: projectRoute({ projectId: target.feed.urn }),
  }),
  file: ({ target }) => ({
    name: target.file.name,
    idTag: 'file',
    route: projectFileRoute({
      projectId: target.project.urn,
      fileId: target.file.urn,
    }),
  }),
  offer: ({ target }) => ({
    name: target.offer.name,
    idTag: 'offer',
    route: offerRoute({ offerId: target.offer.urn }),
  }),
  package: ({ target }) => ({
    name: target.package.name,
    idTag: 'project',
    route: projectRoute({ projectId: target.package.urn }),
  }),
  project: ({ target }) => ({
    name: target.project.name,
    idTag: 'project',
    route: projectRoute({ projectId: target.project.urn }),
  }),
  'project-service': ({ target }) => ({
    name: target.service.name,
    idTag: 'project-service',
    route: projectServiceRoute({
      projectId: target.project.urn,
      projectServiceId: target.service.urn,
    }),
  }),
}
