import {
  ActivityId,
  type AnyUrn,
  FileId,
  OfferId,
  ProjectId,
  ProjectServiceId,
} from '@mntn-dev/domain-types'
import { route } from './route.ts'

export const offerRoute = ({ offerId }: { offerId: OfferId | AnyUrn }) => {
  return route('/offers/:offerId').params({
    offerId: OfferId.parse(offerId),
  })
}

export const projectRoute = ({
  projectId,
}: { projectId: ProjectId | AnyUrn }) => {
  return route('/projects/:projectId').params({
    projectId: ProjectId.parse(projectId),
  })
}

export const projectActivityRoute = ({
  projectId,
  activityId,
}: { projectId: ProjectId | AnyUrn; activityId: ActivityId | AnyUrn }) => {
  return route('/projects/:projectId')
    .params({
      projectId: ProjectId.parse(projectId),
    })
    .query({ tab: 'activity', activityId: ActivityId.parse(activityId) })
}

export const projectFilesRoute = ({
  projectId,
}: { projectId: ProjectId | AnyUrn }) => {
  return route('/projects/:projectId/files').params({
    projectId: ProjectId.parse(projectId),
  })
}

export const projectFileRoute = ({
  projectId,
  fileId,
}: {
  projectId: ProjectId | AnyUrn
  fileId: FileId | AnyUrn
}) => {
  return route('/projects/:projectId/files/:fileId').params({
    projectId: ProjectId.parse(projectId),
    fileId: FileId.parse(fileId),
  })
}

export const projectServiceRoute = ({
  projectId,
  projectServiceId,
}: {
  projectId: ProjectId | AnyUrn
  projectServiceId: ProjectServiceId | AnyUrn
}) => {
  return route('/projects/:projectId/services/:projectServiceId').params({
    projectId: ProjectId.parse(projectId),
    projectServiceId: ProjectServiceId.parse(projectServiceId),
  })
}
