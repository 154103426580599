'use client'

import type { ExtractQueryParams, RouteKey } from '@mntn-dev/app-routing'
import { useSearchParams } from 'next/navigation'

export function useQueryParams<
  TRouteKey extends RouteKey,
>(): ExtractQueryParams<TRouteKey> {
  const searchParams = useSearchParams()

  const query: ExtractQueryParams<TRouteKey> = Array.from(
    searchParams.entries()
  ).reduce<ExtractQueryParams<TRouteKey>>((acc, [key, value]) => {
    acc[key as keyof ExtractQueryParams<TRouteKey>] =
      value as ExtractQueryParams<TRouteKey>[keyof ExtractQueryParams<TRouteKey>]
    return acc
  }, {})

  return query
}
